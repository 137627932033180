<template>
    <div>
        <h2>จัดการหมวดหมู่</h2>
        <v-card class="mt-4 px-6 pt-6">            
            <v-text-field
              outlined
              v-model="datatable.search"
              placeholder="ค้นหา ..."
              dense
            ></v-text-field>
        </v-card>
        
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>        
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'category_create', params: { category_id: 'create' } })">เพิ่มหมวดหมู่</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/product/category/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">            
            <v-simple-checkbox
              v-model="isSelected"
              color="primary"
              @input="select"
              :ripple="false"
            />
          </template>

          <template          
            v-slot:item.name="{ item }"
          >   
          {{ item.parent ? item.parent.name + ">>>" + item.name : item.name }}          
          </template>
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'category_create', params: { category_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Product') ? mdiPlaylistEdit : mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Product') || item.products_count > 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        brand_id: null,
        category_id: null,
        tag_id: null,
        search: null,
        headers: [
          {
            text: 'ชื่อหมวดหมู่',
            value: 'name',
          },
          {
            text: 'จำนวนสินค้า',
            value: 'products_count',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        brand_id: this.datatable.brand_id,
        category_id: this.datatable.category_id,
        tag_id: this.datatable.tag_id,
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteMultiple() {
      const categoryNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const categoryIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + categoryNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/product/category', { items: categoryIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/product/category', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>